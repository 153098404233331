import logo from "./logo.svg";
import "./App.css";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { useForm, ValidationError } from "@formspree/react";
// const raf = useForm(process.env.REACT_APP_FORM_ID);
// console.log("raf", raf);

import {
  Client,
  Account,
  Databases,
  ID,
  Query,
  Functions,
  Storage,
  ExecutionMethod,
} from "appwrite";

function App() {
  const apiUrl = process.env.REACT_APP_API_URL;
  console.log("apiUrl", apiUrl);
  const apiUrl2 = import.meta.env.REACT_APP_API_URL;
  console.log("apiUrl2", apiUrl2);
  const client = new Client();
  client
    .setEndpoint("https://sgp1.iplaycafe.app/v1")
    .setProject("6655fd0b0032aff21139");

  const _account = new Account(client);

  const _loginAuth = async (type = "google") => {
    console.log("_loginAuth type", type);

    let promise;
    // try {
    //   await Account.deleteSessions();
    // } catch (error) {}

    promise = _account.createOAuth2Session(
      "google",
      "http://localhost:3000/",
      "http://localhost:3000/no"
    );

    return promise;
  };
  const _logout = async () => {
    return _account.deleteSessions();
  };
  useEffect(() => {
    try {
      const promise = _account.get();
      promise.then(
        function (data) {
          console.log("promise", data);
        },
        function (error) {
          console.log("promise", error);
        }
      );
    } catch (error) {}
  }, []);
  return (
    <div className="App">
      <header className="App-header">
        <button onClick={_loginAuth}>login</button>
        <button onClick={_logout}>logout</button>
      </header>
    </div>
  );
}

export default App;
